import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import { Flex, Box, Text } from '@chakra-ui/react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const PrivacyPage = ({ intl }) => (
  <Layout>
    <SEO
      title={intl.formatMessage({ id: 'blocto.privacy.meta.title' })}
      description={intl.formatMessage({
        id: 'blocto.privacy.meta.description',
      })}
      path="privacy"
    />
    <Flex
      width="100%"
      height={{ base: '257px', md: '343px', lg: '480px' }}
      px={{ base: '24px', md: '64px', lg: '120px' }}
      pt={{ base: '86px', md: '88px', lg: '112px' }}
      pb={{ base: '72px', md: '104px', lg: '40px' }}
      bg="border.highlight"
    >
      <Flex
        alignItems={{ base: 'flex-end', lg: 'center' }}
        width="100%"
        maxWidth="1200px"
        margin="0 auto"
      >
        <Text
          as="h1"
          color="icon.inverse"
          fontSize={{ base: '44px', md: '72px' }}
          fontWeight="weight.m"
          lineHeight={{ base: '120%', md: '110%' }}
          m="0"
        >
          Privacy Policy
        </Text>
      </Flex>
    </Flex>

    <Flex
      width="100%"
      px={{ base: '24px', md: '64px', lg: '120px' }}
      pt={{ base: '20px', md: '24px', lg: '80px' }}
      pb={{ base: '40px', md: '80px', lg: '160px' }}
      position="relative"
      _before={{
        display: 'block',
        content: '""',
        bg: 'background.primary',
        width: '100%',
        height: { base: '20px', md: '40px' },
        borderRadius: { base: '30px 30px 0 0', md: '60px 60px 0px 0px' },
        position: 'absolute',
        top: { base: '-20px', md: '-40px' },
        left: '0',
      }}
    >
      <Box width="100%" maxWidth="1200px" margin="0 auto">
        <div className="container">
          <div className="content">
            <p>
              portto Co., Ltd. ("portto", "Company", "we", "us", "our") respects your privacy. This
              Privacy Policy ("Policy") explains the privacy practices for the Blocto mobile
              application ("App" or "Blocto"). It also describes the rights and options available to
              you with respect to your information.
            </p>
            <p>
              This Policy is combined into our <a href="/terms/">Terms of Use</a> (the "Terms") and
              is a part of them. Your consent to our collecting, processing, using and protecting
              your personal information according to this Policy is made upon the acceptance of this
              Policy and/or implied upon your use of our services. To protect your rights, please
              read the following thoroughly.
            </p>

            <h3>Personal Information We Collect And Process</h3>
            <p>
              We will collect your information through your computer, mobile phone or other devices
              whenever you use or access our services. We may collect and process:
            </p>
            <p>
              <strong>Initial Account Sign-up Information.</strong> To sign up and create a user
              account on Blocto, we collect your email address.
            </p>
            <p>
              <strong>Support Information.</strong> When you contact us for technical and customer
              support requests, we collect the information included in your request and in follow-up
              interactions we have with you.
            </p>
            <p>
              You do not have a legal duty to provide the above information. However, you will not
              be able to sign up to, and use the full functionalities of Blocto, without providing
              the above information.
            </p>
            <p>
              <b>Survey Information.</b> We may from time to time invite you to participate in
              surveys about your experience using Blocto. If you agree to participate in a survey,
              we collect the information included in your answers to the survey.
            </p>
            <p>
              <strong>Transaction Information.</strong> When you activate your Blocto account, a set
              of contract wallets will automatically be created. We will collect information on all
              transactions originated from your contract wallet created through Blocto.
            </p>
            <p>
              <strong>Device Information.</strong> We collect information about your devices,
              including its model, its operating system, browser you use, its version and language
              preferences, mobile network information and the Internet Protocol (IP) address through
              which you accessed the App.
            </p>
            <p>
              <strong>Analytics Information.</strong> We use third party tools to automatically
              record and collect analytics information about your use of Blocto, including your
              session durations, the content you accessed on the App, your interactions and
              user-interface clicks, the frequency and scope of your use of the App.
            </p>
            <p>
              The overall personal information outlined above will be referred to as the
              "Information" or "Personal Information".
            </p>

            <h3>The Information That We Collect From You Includes:</h3>
            <ul>
              <li>
                Personal Identification data, e.g., names, phone numbers, bank account numbers,
                credit card numbers, ID numbers, copies of ID cards, foreigners' passport numbers
                and passport copies, etc.;
              </li>
              <li>Personalized data, e.g., sex, date of birth, etc.;</li>
              <li>Account data, e.g., users' account names and passwords;</li>
              <li>
                Financial information, e.g., bank accounts, bills issued by banks and transaction
                information;
              </li>
              <li>
                Copies of images of personal identification, including your documents, passport, ID
                card or driver's license issued by the government;
              </li>
              <li>Proof of residence data, including your utilities or other similar data;</li>
              <li>
                Other types of information, e.g., home or work address, email address and
                non-confidential emails.
              </li>
              <li>
                When you use the App or the services thereof, we will collect information regarding
                your transactions and/or other activities you participate in on the App;
              </li>
              <li>
                You may choose to provide us with access to certain Personal Information stored by
                third parties, such as social media. The information we have access to varies by
                site and is controlled by your privacy settings on that third party's site. By
                authorizing us to have access to this information from third parties, you agree that
                we may collect, store and use this information in accordance with this Policy.
              </li>
              <li>
                We automatically obtain data in some computers, devices, and browser history when
                you use our services. These data are collected to conduct statistics that provide
                information of the users' browsing patterns and habits, but for personal use. These
                Personal Information includes data of computers or mobile devices, which includes
                but is not limited to identification codes, names and types of devices, startup
                systems, operating systems, IP addresses, locations, mobile network information and
                general webpage login information (e.g., the browsing of content of certain types,
                website traffic and your saved pages);
              </li>
              <li>
                In a virtual digital currency transaction, we may share information about you with
                the third parties, including but without limitation to your e-mail address or phone
                number. We may use this information in connection with such transactions to
                facilitate such transactions.
              </li>
            </ul>

            <h3>Minor's Privacy</h3>
            <p>
              Blocto is not intended for minors (i.e. people having not reached the age of majority
              in applicable jurisdictions). We do not knowingly or intentionally collect information
              about the minors.
            </p>
            <p>IF YOU ARE UNDER THE AGE OF MAJORITY YOU SHALL NOT USE THE APP IN ANY WAY.</p>
            <p>
              If we become aware that we have inadvertently received Personal Information from a
              minor, we will delete such information from our records.
            </p>

            <h3>How And Where We Process And Use Personal Information</h3>
            <p>
              We process your Initial Account Sign-up Information, back-up email information, and
              Transaction Information to operate Blocto and provide you with its features and
              functionality.
            </p>
            <p>
              We use your back-up email information, Analytics Information and Device Information to
              confirm your identity and prevent fraud.
            </p>
            <p>
              We process your Initial Account Sign-up Information, Transaction Information and
              Analytics Information, to send you administrative notifications relating to Blocto as
              well as promotional updates relating to Blocto ("Promotional Communications"). You can
              control your notification settings and preferences through your device setting.
            </p>
            <p>
              <b>Opt Out.</b> You may also "opt-out" of using your information for promotional
              communications at any time by sending an email to our support service at:{' '}
              <a href="mailto:support@blocto.app">support@blocto.app</a>. By doing so, we will only
              delete your Information from our mailing list, while we will continue to process your
              information submitted to us which is necessary to provide you with our services.
            </p>
            <p>
              We process your Analytics Information to understand how users interact with Blocto so
              that we can further develop and enhance Blocto.
            </p>
            <p>
              We process your Initial Account Sign-up Information, Analytics Information and Support
              information to provide you technical or customer support in response to inquiries on
              these topics.
            </p>
            <p>
              We process your Initial account Sign-up information to contact you, from time to time,
              and invite you to participate in surveys about your experience using Blocto. If you
              agree to participate in a survey, we use your answers to further enhance and develop
              Blocto, or as otherwise explained in each particular survey.
            </p>
            <p>
              Information we collect from you will be used and processed by portto in the Cayman
              Islands and other regions where our services are provided, including regions outside
              the Cayman Islands where we deem necessary to use the Personal Information according
              to our business judgment.
            </p>

            <h3>When And How Your Personal Information Is Shared With Others</h3>
            <p>
              We will not share your Information with third-parties, except in the events listed
              below or when you provide us with your explicit and informed consent.
            </p>
            <p>
              We will share your Information with our service providers, its subsidiaries or
              affiliates who assist us with the internal operations of Blocto. These companies are
              authorized to use your Personal Information only as necessary to provide these
              services to us and not for their own promotional purposes. We do not sell your
              Personal Information to third parties.
            </p>
            <p>
              If you use Blocto to access third-party DApps, Blocto will share your digital wallet
              address with these DApps under your consent, in order for these DApps to function
              properly.
            </p>
            <p>
              If you use Blocto to purchase crypto assets from third party platforms, Blocto will
              share your digital wallet address with these crypto-purchase platforms in order to
              enable the transfer of the purchased crypto assets to your Blocto wallet.
            </p>
            <p>
              If you violated any applicable law, your Information will be shared with competent
              authorities and with third parties (such as legal counsels and advisors), for the
              purpose of handling the violation.
            </p>
            <p>
              If we are required to disclose your information by a judicial, governmental or
              regulatory authority, we will disclose your information in order to comply with our
              legal or regulatory obligations or at their request in furtherance of their
              objectives.
            </p>
            <p>
              In any of the events set forth above, with a view to protecting the privacy of our
              users, we shall ensure the third party or its affiliates protect the privacy of our
              users to the minimum extent, which shall be no less than the personal data security
              and maintenance standard required by the Policy.
            </p>
            <p>
              If you make a transaction on the App, you or portto may provide your counterparty with
              your Personal Information for the purpose of fulfilling the transaction. Unless you
              have given prior consent, such counterparty is not allowed to use this information for
              other purposes. If an attempt to transfer virtual digital currency to your
              counterparty fails or is later invalidated, we may also provide your counterparty with
              details of the unsuccessful transfer. To facilitate dispute resolutions, we may
              provide other users with your Personal Information, such as contact details, if it is
              deemed necessary in our discretion.
            </p>
            <p>
              If we believe that it is necessary to disclose your Personal Information to avoid any
              personal injury or property loss and/or report suspected illegal conduct or
              investigate the violation of our Terms of Use we may disclose your information.
            </p>
            <p>
              Unless you have given prior consent, we will not use your Personal Information in any
              way other than those described above.
            </p>

            <h3>Use Of Third-Party Crypto-Purchase Platforms</h3>
            <p>
              Blocto enables you to use other, third party platforms to purchase crypto assets using
              fiat currency.
            </p>
            <p>
              Your use of such third-party platforms and those platforms' use of your information
              are governed by their respective terms of use and privacy policy, not by this Policy.
            </p>
            <p>
              These platforms will go through the Know-Your-Client process with you, collect and
              process your information, execute the crypto purchase, and send back the crypto your
              Blocto wallet. This purchase service is fully operated by these platforms, not Blocto.
              You personal data is collected by them and you will have to agree to their terms of
              service. They are the controller of your personal data that they process.
            </p>
            <p>
              These third-party platforms will not share the information you provide to them with
              Blocto.
            </p>
            <p>
              Blocto will only share your digital wallet address with these crypto-purchase
              platforms in order to enable the transfer of the purchased crypto assets to your
              Blocto wallet.
            </p>

            <h3>Security And Information Retention</h3>
            <p>
              We retain Personal Information for the duration needed to support our ordinary
              business activities operating Blocto, the retention period required by the law or a
              contract, or the expiration date which we have decided on, whichever period is the
              longest. Thereafter, we will continue to retain your Personal Information as necessary
              to comply with our legal obligations, resolve disputes, establish and defend legal
              claims and enforce the Terms.
            </p>
            <p>
              We implement measures to reduce the risks of damage, loss of information and
              unauthorized access or use of information. However, these measures do not provide
              absolute information security. Therefore, although efforts are made to secure your
              Personal Information, it is not guaranteed, and you cannot expect that the Service
              will be immune from information security risks.
            </p>
            <p>
              To protect your privacy, we shall provide reasonable techniques and measures in
              accordance with the applicable laws to protect personal data and transaction data.
              This is to avoid the loss, misuse, unauthorized use, access, disclosure, alteration or
              destruction of personal data (e.g. the use of firewalls and data encryption, building
              access control or data access restrictions and other physical control, or restricting
              any staff's access, except for those whose types of tasks require access to the data).
              To avoid the leakage of Personal Information, please guard your Personal Information,
              registered account and password. Except for the case where it is required by the
              government or the law, or a disclosure made by us to share or provide information for
              a third party's use pursuant to the Policy, we are obliged to keep the Personal
              Information confidential.
            </p>
            <p>
              We shall request any third party in cooperation with us that has the authority to
              obtain, share or process our users' Personal Information pursuant to the Policy, to
              keep our users' Personal Information confidential and maintain the information as
              required by the provisions set forth above. However, in any case where a third party's
              conduct is liable for the damages of the users' rights, we shall assist members in
              claiming rights against the third party. However, we shall not be liable for any
              damages caused by the third party.
            </p>

            <h3>Rights to Personal Information</h3>
            <p>
              As to the Personal Information you provided to us, you may exercise the following
              rights:
            </p>
            <ul>
              <li>
                Look up or request for an examination or make copies of your Personal Information.
                We may charge necessary costs and expenses pursuant to the law.
              </li>
              <li>
                Request us to supplement or make corrections to your Personal Information. You shall
                provide a reasonable explanation when exercising this right.
              </li>
              <li>
                Request us to cease collecting, processing or using your Personal Information, or to
                delete your Personal Information.
              </li>
            </ul>
            <p>
              Should you wish to exercise the aforementioned rights, you may send your request to{' '}
              <a href="mailto:support@blocto.app">support@blocto.app</a>, and we shall act upon your
              request.
            </p>
            <p>
              However, prior to our verification of your identity, you may not request to look up,
              make copies or examine your Personal Information or account details or apply for an
              alteration thereto.
            </p>
            <p>
              You may request us to cease collecting, processing, using or deleting your Personal
              Information pursuant to any provision set forth above. However, we reserve the right
              to terminate the services of this App once we have discontinued the collection,
              process, use or deletion of your Personal Information.
            </p>
            <p>
              You acknowledge and agree that due to the nature of our operations and business, the
              maintenance of transactional security and the establishment of a reliable user review
              mechanism are of utmost importance to our business. We reserve the right to refuse
              your request for alteration, deletion or change of Personal Information in the event
              that we are of the opinion that such request will result in interference with the
              nation's material interests, interference with the public authorities' execution of
              its legal duties, and hindrance to the material interests of a data-collecting
              authority or a third party, or if we are required to continue keeping your Personal
              Information by its duties and business. Depending on the circumstances, we may change
              the way it processes your Personal Information (e.g. temporarily seal your
              information) until such time when the foregoing situations have been resolved, and
              then alter, delete or change your Personal Information as requested.
            </p>
            <p>
              You may freely decide whether to provide your Personal Information to this App for its
              services. However, should you refuse to provide the required information necessary for
              the completion of the registration process, or you omit certain data, which might
              result in the inability of this App to execute its services, we may deny your
              application for relevant trades or services.
            </p>
            <p>
              You agree that we reserve the right to amend the aforementioned provisions on the
              exercise of your rights to your Personal Information, and to inform you of the summary
              of the amended provisions and designated Apps or websites publishing the amendments.
              We may inform you orally, in writing or by telephone, text, email, fax, electronic
              documents, announcements made by our office or disclosed on its website or any other
              method (including but not limited to providing access to any website that discloses
              the amendments using the means mentioned above) that is sufficient for you to perceive
              or understand the contents of the amendments.
            </p>

            <h3>Additional Information For Users In The EU (If Applicable)</h3>
            <p>
              Controller. portto Co., Ltd. is the data controller of the personal data we process
              about you. portto is registered at: Suite 102, Cannon Place, North South Rd., George
              Town, Grand Cayman, Cayman Islands.
            </p>
            <p>
              <b>Legal Basis for Processing Your Data.</b>
            </p>
            <ul>
              <li>
                The legal basis under EU law for processing your Information for the purpose of
                operating Blocto and providing its features is the performance of our Term of Use,
                which shall be deemed a contract between us and our legitimate interests in the
                proper management and operation of Blocto.
              </li>
              <li>
                The legal basis under EU law for processing your Information for the purpose of
                sending you notifications (including Promotional Communications) relating to Blocto
                is our legitimate interests in informing users of administrative issues and
                encouraging user engagement with Blocto.
              </li>
              <li>
                The legal basis under EU law for processing your Information for the purpose of
                confirming your identity and preventing fraud is the performance of our Term of Use,
                which shall be deemed a contract between us, our legitimate interests in keeping
                Blocto free of fraudulent conduct and our necessity for compliance with legal
                obligations.
              </li>
              <li>
                The legal basis under EU law for processing Information to provide you technical or
                customer support is our legitimate interests in assisting our users on issues
                relating to their use of Blocto.
              </li>
              <li>
                The legal basis under EU law for processing Analytics Information to further develop
                and enhance Blocto is our legitimate interests in fostering and advancing Blocto.
              </li>
              <li>
                The legal basis for collecting and processing Survey Information is your consent, as
                well as our legitimate interest in developing and enhancing Blocto.
              </li>
              <li>
                The legal basis for sharing your Information with competent authorities and with
                third parties for the purpose of handling violations is our legitimate interests in
                enforcing our legal rights.
              </li>
              <li>
                The legal basis for disclosing your Information at the request of a judicial,
                governmental or regulatory authority is our compliance with legal obligations to
                which we are subject.&nbsp;
              </li>
              <li>
                The legal basis for sharing your digital wallet address with crypto-purchase
                platforms is for the performance of the crypto purchase transaction contract.
              </li>
              <li>
                The legal basis for processing your Information for the purpose of notifying you or
                providing you with updates to this Policy is our necessity for compliance with legal
                obligations under the General Data Protection Regulation (“GDPR”).
              </li>
            </ul>
            <p>
              <b>Cross-Border Data Transfers.</b>
            </p>
            <p>
              Information we collect from you will be used and processed by portto in the Cayman
              Islands, and other regions where the services are provided, including regions outside
              the Cayman Islands which are recognized by the European Commission as having adequate
              protection for personal data.
            </p>
            <p>
              When we transfer your Information from Europe to other jurisdictions outside Europe,
              we will do so by using adequate safeguards determined by the EU Commission.
            </p>
            <p>
              <b>EU Rights.</b> If you are in the EU, you have the following rights under the GDPR:
            </p>
            <ul>
              <li>
                Right to Access your Personal Information that we process and receive a copy of it.
              </li>
              <li>
                Right to Rectify inaccurate Personal Information we have concerning you and to have
                incomplete Personal Information completed.
              </li>
              <li>
                Right to Data Portability, that is, to receive the Personal Information that you
                provided to us, in a structured, commonly used and machine-readable format. You have
                the right to transmit this Information to another service provider. Where
                technically feasible, you have the right that your Personal Information be
                transmitted directly from us to the service provider you designate.
              </li>
              <li>
                Right to Withdraw Consent to processing your Personal Information, at any time,
                where the processing is based on consent. If you do that, we will still process
                certain information on a legal basis other than consent, as described in this
                Policy. Withdrawing your consent will not affect the lawfulness of the data
                processing we carried out based on your consent before such withdrawal.
              </li>
              <li>
                Right to Object, based on your particular situation, to use your Personal
                Information on the basis of our legitimate interests. However, we may override the
                objection if we demonstrate compelling legitimate grounds, or for the establishment,
                exercise or defense of legal claims. You may also object at any time to the use of
                your Personal Information for direct marketing purposes.
              </li>
              <li>
                Right to Restrict processing your Personal Information (except for storing it) if
                you contest the accuracy of your Personal Information, for a period enabling us to
                verify its accuracy; if you believe that the processing is unlawful and you oppose
                the erasure of the Personal Information and request instead to restrict its use; if
                we no longer need the Personal Information for the purposes outlined in this Policy,
                but you require them to establish, exercise or defend legal claims; or if you object
                to processing, pending the verification whether our legitimate grounds for
                processing overrides yours.
              </li>
              <li>
                Right to be Forgotten. Under certain circumstances, such as when your right to
                object overrides our legitimate interests, you have the right to ask us to erase
                your Personal Information. However, we may still process your Personal Information
                if it is necessary to comply with a legal obligation, we are subject to under laws
                in EU Member States or for the establishment, exercise or defense of legal claims.
              </li>
            </ul>
            <p>
              If you desire to exercise any of these rights, contact us at{' '}
              <a href="mailto:support@blocto.app">support@blocto.app</a>.
            </p>
            <p>
              We reserve the right to ask for reasonable evidence to verify your identity before we
              provide you with information. Where we are not able to provide you with information
              that you have asked for, we will explain the reason for this.
            </p>
            <p>
              Subject to applicable law, you have the right to lodge a complaint with your local
              data protection authority. If you are in the EU, then according to Article 77 of the
              GDPR, you can lodge a complaint to the supervisory authority, in particular in the
              Member State of your residence, place of work or of an alleged infringement of the
              GDPR. For a list of supervisory authorities in the EU,{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
              >
                click here
              </a>
              .
            </p>

            <h3>Others</h3>
            <p>
              The services of this App may include links to other websites or pages, or services
              provided by a third party. Should you also use other additional functions, plug-ins or
              applications provided by third parties (hereinafter referred to as "Third Party
              Applications"), the aforementioned websites, pages or Third Party Applications may
              obtain some type of your personal information. Please be mindful that the collection
              of Personal Information by websites, pages or Third Party Applications that are not
              owned by us are irrelevant to us.
            </p>
            <p>
              If you authorize any third-party website or Third Party Application to access your
              Blocto wallet, then the information you have provided to us may be shared with such
              third parties. Please note that the third-party websites or Third Party Applications
              may have their own privacy policies, and we are not responsible for their disposal of
              your Personal Information.
            </p>
            <p>
              Should the services of this App be conferred to a third party irrelevant to us in the
              future, or in events where we are to be acquired or merged by a third party, your
              Personal Information shall be transferred in accordance with the maintenance
              requirements set forth in the Policy.
            </p>

            <h3>Changes To This Privacy Policy</h3>
            <p>
              From time to time, we may change this Policy, in which case we will inform you of the
              updated Policy by emailing you.
            </p>

            <h3>CONTACT US</h3>
            <p>
              Should you have any inquiries, complaints or suggestions, you may contact us at{' '}
              <a href="mailto:support@blocto.app">support@blocto.app</a>. We will do our best to
              resolve your issue in a timely manner.
            </p>
            <p>
              <i>Last Update: Aug. 30, 2023.</i>
            </p>
          </div>
        </div>
      </Box>
    </Flex>
  </Layout>
);

PrivacyPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object.isRequired,
};

export default injectIntl(PrivacyPage);
